.content {
    height: calc(100vh - 4rem);
    padding: 2rem;
    overflow-y: auto;

    .home-heading {
        font-size: 2.25rem;
        margin: 0;
        padding: 0;
    }

    @media only screen and (max-width: 768px) {
        padding: 4rem 2rem;
        height: auto;
    }
}

.wrapper {
    margin: 1rem;

    @media only screen and (max-width: 768px) {
        margin: 0;
    }

    .row-content {
        display: flex;
        flex-direction: row;
        align-content: center;
        margin-bottom: 2rem;
        width: 100%;

        form.row {margin-bottom: 0;}

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: space-between;
            form.row {
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2rem;
            }
        }
    }

    &__heading {
        margin: 0;
        border-bottom: 1px solid white;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
            color: white;
            margin-right: 1rem;
            display: grid;
            place-content: center;
        }

        @media only screen and (max-width: 768px) {
            font-size: 1.35rem;
        }
    }

    &__list {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li a {
            display: block;
            color: white;
            font-size: 1.25rem;
            font-weight: 500;
            text-decoration: none;
            background: #ffffff05;
            padding: .75rem 1.5rem;
            border-radius: .5rem;
            margin-bottom: 1.25rem;
            transition: .3s;
            max-width: 30%;
            box-shadow: 0 0 15px 5px rgba(255, 255, 255, .05);
            border: 1px solid #ffffff21;

            &:hover {background: #ffffff0a;}

            @media only screen and (max-width: 768px) {
                max-width: 100%;
                font-size: 1.1rem;
            }
        }

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            color: white;
            background: #ffffff05;
            padding: .75rem 1rem;
            border-radius: .5rem;
            margin-bottom: 1rem;
            transition: .3s;
            max-width: 40%;
            min-width: 400px;
            box-shadow: 0 0 15px 5px rgba(255, 255, 255, .05);
            border: 1px solid #ffffff21;
            box-sizing: border-box;

            &:hover {background: #ffffff0a;}

            p {font-size: 1.25rem; margin: 0;}
            .btn-danger {padding: .5rem .75rem;}
            .btn-danger svg {width: 1.25rem; height: 1.25rem;}

            @media only screen and (max-width: 768px) {
                max-width: 100%;
                min-width: auto;
                .btn-danger {padding: .4rem .6rem; font-size: 1rem;}
            }
        }

        .form {
            margin-top: 3rem;
        }

    }

    &__form {
        width: 40%;

        .form-group, input, textarea {width: 100%;}
        textarea {height: auto; min-height: 200px;}
        .btn {margin: auto; width: 100%;}

        @media only screen and (max-width: 768px) {
            width: 100%;

            .form-group {width: 100%;}
            textarea {height: 300px;}
        }
    }

    &__gallery {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;

        form {
            .form-group input {
                font-size: 1rem;
                padding: .25rem;
            }

            .btn-submit {
                font-size: 1rem;
                padding: .5rem 1.5rem;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 1rem;
                .form-group, select {width: 100%;}
            }
        }

        .uploadInfo {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 2rem;

            p {
                margin: 0;
                padding: 0;
                margin-bottom: .5rem;
            }

            .bar::before {
                background: $light-blue;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-left: 0;
                margin-bottom: 2rem;
                .bar {width: 100%;}
            }
        }

        .gallery {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            flex-wrap: wrap;
            width: 100%;
            flex-direction: row;

            @media only screen and (max-width: 768px) {
                justify-content: space-evenly;
                gap: .5rem;
            }

            .image-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                img {
                    width: 250px;
                    height: 250px;
                    object-fit: cover;
                    overflow: hidden;
                    border-radius: .5rem;
                    @media only screen and (max-width: 768px) {
                        width: 190px;
                        height: 190px;
                    }
                    @media only screen and (max-width: 500px) {
                        width: 150px;
                        height: 150px;
                    }
                }

                @media only screen and (max-width: 768px) {
                    width: 200px;
                }
                @media only screen and (max-width: 500px) {
                    width: 150px;
                }

                .image-options {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: 90%;
                    margin-top: .5rem;

                    select {
                        padding: .15rem .25rem;
                        margin: 0;
                        background: none;
                        border: $border;
                        border-radius: .2rem;
                        offset: 0;
                        outline: none;
                        color: white;
                        font-size: 1rem;
                        font-family: $roboto;
                        cursor: pointer;

                        option {
                            color: white;
                            background: $lighter-blue;

                            &:hover {
                                background: $blue;
                            }
                        }
                    }

                    .btn-danger {
                        padding: .25rem .5rem;
                    }

                    @media only screen and (max-width: 768px) {
                        select {width: 75%;}
                    }
                    @media only screen and (max-width: 500px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__user {
        h3 {font-size: 1.25rem;}

        .avatar-images {
            margin-top: 2rem;

            .images {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;

                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: .2rem;
                    border-radius: 100px;
                    opacity: .4;
                }

                .active {opacity: 1;}
                img {width: 100px; height: 100px;}
            }

            .btn-submit {
                margin-top: 1rem;
                font-size: 1.1rem;
                padding: .5rem 1rem;
            }
        }

        form {
            width: 100%;
            .form-group {
                width: 100%;
                p {font-size: 1rem;}
                input {width: 100%;}

                .green-border {border: 1px solid #08a508;}
                .red-border {border: 1px solid #c10000;}
            }

            .btn-submit {
                font-size: 1.1rem;
                padding: .5rem 1rem;
                width: 100%;
            }
        }

        .theme-change {
            margin-top: 2rem;
            .switch .btn {margin-right: .5rem;}
        }

        @media only screen and (max-width: 768px) {
            .avatar-images .images img {width: 50px; height: 50px;}
            form .form-group {width: 100%;}
        }
    }
}
