// Toastify

.Toastify__toast-container {
    width: 20% !important;
    bottom: 1rem !important;
    right: 1rem !important;
    top: auto !important;

    @media only screen and (max-width: 500px) {
        width: calc(100% - 2rem) !important;
        bottom: 1rem !important;
        right: 1rem !important;
        left: auto !important;
    }
}

// Modals

.ReactModal__Overlay {
    z-index: 1000;
    background-color: #00000098 !important;

    .ReactModal__Content {
        background: $blue !important;
        border: $border !important;
        border-radius: $border-radius !important;
        width: 50vw;
        height: fit-content;
        margin: auto;
        padding: 0 !important;
        inset: 0 !important;

        .modal {
            text-align: center;
            padding: 2rem;

            h3 {
                margin: 0;
                padding: 0;
                margin-bottom: 2rem;
            }

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                gap: 2rem;

                &.options {
                    margin-bottom: 1rem;
                    gap: 1rem;

                }
            }
        }

        .edit-modal {
            .image-container {
                height: 500px;
                width: 500px;
                margin: auto;
                position: relative;
                margin-bottom: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
            }
        }

        @media only screen and (max-width: 768px) {
            width: 95vw;
            .edit-modal .image-container {
                width: 90%;
                height: 400px;
            }
        }
    }
}

