.nav {
    position: relative;
    background: $lighter-blue;
    height: 100vh;
    margin: 0;
    padding: 0;

    .heading-link {
        color: white;
        text-decoration: none;
    }

    .heading {
        margin: 0;
        padding: 0;
        text-align: center;
        padding-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {margin-right: .5rem; font-size: 1.75rem;}
    }

    &__menu {
        margin: 0;
        padding: 1rem;
        width: auto;
        list-style: none;
    }

    &__link {
        padding: 0;
        width: 100%;
        margin: 0;
        margin-bottom: .5rem;

        &:first-child {
            margin-top: .75rem;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: .75rem 1rem;
            width: calc(100% - 2rem);
            border-radius: $border-radius;
            color: #bbbbbb;
            font-size: 1.1rem;
            font-family: $montserrat;
            font-weight: 500;
            text-decoration: none;
            transition: .3s;

            &:hover, &.active {color: white; background: #ffffff09;}

            .icon {margin-right: .75rem; font-size: 1.2rem;}
        }
    }

    .user {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: .5rem 1.5rem;
        text-decoration: none;
        color: white;
        transition: .3s ease;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;

        .link-button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        &__avatar img {
            width: 2.5rem;
            height: 2.5rem;
        }

        &__info .name {
            font-size: 1.1rem;
            font-weight: 500;
            margin-left: 1rem;
        }

        .logout {
            position: absolute;
            right: 1rem;
            font-size: 1.25rem;
            padding: .75rem;
            border-radius: $border-radius;
            background: $lighter-blue;
            transition: .3s;
            cursor: pointer;
            z-index: 10;
            display: grid;
            place-content: center;

            &:hover {background: $blue;}
        }

        &.active, &:hover {background: #ffffff0a;}
    }
}

.menu-btn {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    margin: .75rem;
    background: none;
    border: none;
    offset: 0;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 25px;
    z-index: 100;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        background: $lighter-blue;
        width: 40px;
        height: 40px;
        border-radius: .5rem;
        transform: translate(-50%, -50%);
        z-index: -1;
        transition: .3s;
    }

    .line {
        position: absolute;
        left: 0;
        height: 3px;
        background: white;
        border-radius: 100px;
        transition: .5s;
        transform-origin: center;

        &:nth-child(1) {width: 55%; top: 0; transform-origin: left;}
        &:nth-child(2) {width: 100%; top: 50%; transform: translateY(-50%);}
        &:nth-child(3) {width: 55%; left: 45%; top: 100%; transform: translateY(-50%); transform-origin: right;}
    }

    &.open {
        &::after {
            border-radius: 100px;
            background: #0505058b;
        }

        .line {
            &:nth-child(1) {transform: rotate(45deg); left: 15%; top: 5%;}
            &:nth-child(2) {transform: rotate(-45deg);}
            &:nth-child(3) {transform: rotate(45deg) translateY(-50%); left: 30%; top: 95%;}
        }
    }
}

@media only screen and (max-width: 768px) {
    .nav {
        position: fixed;
        top: 0;
        left: -100%;
        width: 75%;
        transition: .5s ease;
        z-index: 90;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        height: 100%;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 133%;
            content: '';
            opacity: 0;
            transition: .5s;
            z-index: -1;
            display: none;
        }

        &.open {
            left: 0%;
            &::after {display: block;}
        }
    }
}
