.light-theme {
  background: $light-gray;
  color: black;

  .nav {
    background: $white;
    .heading-link {color: $blue;}
    &__link a {
      color: $dark-gray;
      background: transparentize($light-gray, .8);
      &:hover, &.active {
        color: black;
        background: transparentize($light-gray, .4);
      }
    }
    .user {
      color: $blue;
      &:hover {background: $light-gray;}
      .logout {
        background: $white;
        color: black;
      }
    }
  }

  .menu-btn {
    &::after {background: white;}
    .line {background: black;}
    &.open {&::after {background: $light-gray;}}
  }

  .wrapper {
    &__heading {
      border-color: $blue;
      a {color: black;}
    }

    &__list {
      li a {
        color: $dark-gray;
        border-color: $dark-gray;
        &:hover {background: $white;}
      }

      &-item {
        color: black;
        border-color: $dark-gray;
      }
    }

    &__gallery .gallery .image-options {
      select {
        background: white;
        border-color: $dark-gray;
        color: black;
      }

      select option {
        color: black;
        background: $white;
      }
    }
  }

  form {
    input, textarea, select {
      background: $light-gray;
      border-color: $dark-gray;
      color: black;

      &:focus {
        background: $white;
        border-color: black !important;
      }
    }

    select option {
      background: $white;
      color: black;
    }
  }


  // BUTTONS
  .btn-primary {
    background: $white;
    border-color: $dark-gray;
    color: black;
    &:hover {background: $light-gray;}
  }

  .btn-change {
    background: $light-gray;
    color: black;
    border-color: $dark-gray;
    &:hover, &.active {background: $white;}
  }


  // MODALS
  .ReactModal__Content {
    background: $white !important;
    border-color: $dark-gray !important;
  }


  // LOADERS
  .skeleton-item a::before {
    background-image: linear-gradient(65deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.037) 30%,
    rgba(0, 0, 0, 0.107) 70%,
    rgba(0, 0, 0, 0));
  }

  .loading {
    .icon {display: block;}
  }


  // LOGIN
  .login {
    .content {
      background: $white;
    }

    .form .btn {
      background: $light-gray;
      color: black;
      border-color: $dark-gray;

      &:hover {background: $white;}
    }
  }
}