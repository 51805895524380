.btn {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    offset: 0;
    outline: none;
    color: white;
    cursor: pointer;
    transition: .3s;

}

.btn-primary {
    padding: .75rem 1.75rem;
    background: $lighter-blue;
    border-radius: .5rem;
    border: $border;
    font-size: 1rem;
    font-family: $montserrat;

    &:hover {background: #0e102d;}
}

.btn-submit {
    padding: .75rem 1.75rem;
    background: $light-blue;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: $montserrat;

    &:hover {background: #1820be;}
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.btn-danger {
    padding: .75rem 1rem;
    background: #9c0000;
    border-radius: .5rem;
    font-size: 1.1rem;
    font-family: $montserrat;
    svg {width: 1.1rem; height: 1.1rem;}

    &:hover {background: #c10000;}
}

.btn-change {
    padding: .5rem 1.75rem;
    background: $blue;
    color: $white;
    border-radius: .5rem;
    font-size: 1rem;
    font-family: $montserrat;
    border: $border;

    &:hover, &.active {background: #0e102d;}
}
