.loading {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-content: center;

    .icon {
        width: 4rem;
        height: 4rem;
        animation: loading 1s ease-in-out infinite;
    }
}

.btn-loading {
    background: $blue;
    border: $border;
    padding-left: 1rem;
    padding-right: 1rem;
    &:hover {
        background: $blue;
    }
    .icon {
        animation: loading 1s ease-in-out infinite;
    }
}

@keyframes loading {
    from {transform: rotate(0deg);}
    to, 80% {transform: rotate(360deg);}
}

.skeleton-item a {
    position: relative;
    border: 1px solid white;
    overflow: hidden;
    height: 1.25rem;
    text-indent: -5000px;

    @media only screen and (max-width: 768px) {
        height: 1.1rem;
    }

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(65deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.0156862745) 30%,
            rgba(255, 255, 255, 0.0823529412) 70%,
            rgba(255, 255, 255, 0));
        transform: translateX(-100%);
        content: '';
        animation: skeleton 2s infinite;
    }
}

@keyframes skeleton {
    100%{transform: translateX(100%);}
}
