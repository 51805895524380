.login {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .content {
        max-width: 500px;
        height: auto;
        padding: 3rem;
        border-radius: $border-radius;
        box-shadow: 0 0 0 #ffffff1a;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        animation: loadInBoxShadow 1s ease forwards;
    }

    .headline {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0;
        text-align: center;
        opacity: 0;
        animation: loadInOpacity .5s .4s ease forwards;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {margin-right: 1rem; height: 2.5rem; width: 2.5rem; display: block;}
    }

    .description {
        font-size: 1.1rem;
        max-width: 90%;
        text-align: center;
        margin: auto;
        margin-bottom: 2.5rem;
        font-family: $montserrat;
        opacity: 0;
        animation: loadInOpacity .5s .4s ease forwards;
    }

    .form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        opacity: 0;
        animation: loadInOpacity .5s .4s ease forwards;

        .form-group {width: 100%;}
        p {text-align: center; margin-bottom: .5rem;}
        input {transition: .3s; box-shadow: none; width: 100%;}
        input:focus {border: $border;}
        .btn {
            padding: 1rem 0;
            font-size: 1.1rem;
            margin-top: 1rem;
            background: $blue;
            border: 1px solid #ffffff36;
            width: 100%;
            box-sizing: border-box;

            &:hover {border: $border; background: $blue;}
        }
    }

    @media only screen and (max-width: 768px) {
        .content {width: 85%; padding: 2rem 1rem;}
        .headline {font-size: 2rem;}
        .description {font-size: 1rem;}
    }
}

@keyframes loadInBoxShadow {
    0%, 20% {box-shadow: 0 0 0px #ffffff1a;}
    80%, 100% {box-shadow: 0 0 30px #ffffff1a;}
}

@keyframes loadInOpacity {
    to {opacity: 1;}
}
